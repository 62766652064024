















































































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// LIBRARIES
import { DateTime } from 'luxon'

// COMPONENTS
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import RequestFormPartWrapper from '@/components/RequestFormPartWrapper.vue'
import CharacterCard from '@/components/cards/CharacterCard.vue'
import ControlsRequestFormPart from '@/components/forms/parts/ControlsRequestFormPart.vue'
import QuartersFormPart from '@/components/forms/parts/QuartersFormPart.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'

// INTERFACES
import { AuthorRequest, CreateActOfReconciliationRequest, UserTypeEnum } from '@/store/types'

// STORE
import FleetOrderModule from '@/store/modules/fleet/order'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    DefaultLayout,
    ValidationProvider,
    ValidationObserver,
    CharacterCard,
    ControlsRequestFormPart,
    RequestFormPartWrapper,
    QuartersFormPart,
    CaptionCard,
  },
})
export default class ActOfReconciliationOrderCreate extends Mixins(SystemMixin, NotifyMixin) {
  private rules = {
    count: (value: string) => value.length <= 500,
  }

  // FORM
  private form: CreateActOfReconciliationRequest = {
    year: '',
    quarters: [],
    author: {} as AuthorRequest,
    comment: '',
  }

  private isShowComment = false

  private years: string[] = []

  private get isNotCompleted (): boolean {
    return (
      this.form.quarters.includes(this.currentQuarter.toString())
      && DateTime.now().year.toString() === this.form.year
    )
  }

  private get currentQuarter (): number {
    return Math.ceil(DateTime.now().month / 3)
  }

  private get quarters (): string[] {
    const all = ['1', '2', '3', '4']

    const index = all.indexOf(this.currentQuarter.toString())

    if (DateTime.now().year.toString() === this.form.year) {
      return all.slice(0, index + 1)
    } else {
      return all
    }
  }

  private created () {
    FleetOrderModule.getOrderDictionaries({ entity: +this.entity, params: { hasRepair: false } })
  }

  private mounted () {
    for (let i = 2004; i <= DateTime.now().year; i++) {
      this.years.push(i.toString())
    }
  }

  private handleChangeYear (value: string) {
    if (DateTime.now().year.toString() === value) {
      this.form.quarters = this.quarters.filter(item => this.form.quarters.includes(item))
    }
  }

  private handleCloseComment () {
    this.isShowComment = false
    this.form.comment = ''
  }

  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          this.form.author.type = this.role === 'fleet' || this.role === 'mechanic'
            ? 'manager' as UserTypeEnum
            : 'driver' as UserTypeEnum

          FleetOrderModule.sendActOfReconciliation({ entity: +this.entity, body: this.form })
            .then((r) => {
              this.$openModal('OrderSendSuccessModal', 'big', {
                route: {
                  name: `view-order.${r.type}`,
                  params: { orderId: r.id },
                },
              })
              this.$router.push({ name: 'orders' })
            })
            .catch(() => {
              this.notifyError('Заявка не отправлена. Необходимо заполнить все обязательные поля.')
            })
        }
      })
  }
}
